import { PureComponent } from 'react';
import Calendar from './Calendar';
import {fallbackRender, getCalendarAPIURL} from './utils';
import {fromUTCStrings} from './utils/EventTimes';
import { ErrorBoundary } from 'react-error-boundary';
import { HtmlInterfaceProps } from "./ts/interfaces/html-interface.tsx";
import {ApiEventData} from "./ts/interfaces/api-interfaces.tsx";

export default class CalendarEventApp extends PureComponent<HtmlInterfaceProps, CalendarStateProps> {
  private requestURL: string;
  constructor(props: HtmlInterfaceProps) {
    super(props);
    const baseURL = 'https://api.socialschools.eu';
    const communityID = this.props.communityId;
    const show = parseInt(this.props.show, 10);
    this.requestURL = getCalendarAPIURL(baseURL, communityID, show);
    this.state = {
      eventsList: []
    };
  }

  componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    fetch(this.requestURL)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        return {
          results: []
        };
      })
      .then(json => {
        self.setState({ eventsList: json.results.map(fromUTCStrings) });
      });
  }
  render() {
    return (
      <ErrorBoundary
        fallbackRender={fallbackRender}>
        <Calendar events={this.state.eventsList} />
      </ErrorBoundary>
    );
  }
}

interface CalendarStateProps {
  eventsList: ApiEventData[];
}
