import React, { PureComponent } from 'react';
import './CustomToolbar.css';

let navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

// CustomToolbar
//
//This components override the default toolbar provided by the calendar
//
//
export default class CustomToolbar extends PureComponent<CustomToolbarProps> {
  render() {
    const { view, views, label, localizer } = this.props;
    return(
      <div className='row custom-toolbar'>
       <div className='rbc-toolbar col-md-4 rbc-toolbar-buttons'>
        <span className="rbc-btn-group">
          <button type="button" value={'PREV'} onClick={this.navigate.bind(null, navigate.PREVIOUS)}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
          <button type="button" value={'TODAY'} onClick={this.navigate.bind(null, navigate.TODAY)}>Vandaag</button>
          <button type="button" value={'NEXT'} onClick={this.navigate.bind(null, navigate.NEXT)}><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
        </span>
      </div>
      <div className='col-md-4 rbc-toolbar-title'>
        <h2><span className="rbc-toolbar-label">{label}</span></h2>
      </div>
      <div className='col-md-4 rbc-toolbar-select'>
        <select value={view}
          onChange={this.handleChange.bind(this)}
          className='custom-select'
        >
          {views.map((value: string, key) => <option value={value} key={key}>{localizer.messages[value]}</option>)}
        </select>
      </div>
    </div>
    );
  }

  handleChange(event: React.ChangeEvent<HTMLSelectElement>)  {
    // console.log(event.target.value);
    this.props.onView(event.target.value);
  }
  // check this for the sample implementation for this https://github.com/intljusticemission/react-big-calendar/blob/master/src/Toolbar.js#L18
  navigate = (action: string) => {
    // console.log('action', action);
    this.props.onNavigate(action)
  }

}

interface CustomToolbarProps {
  view: string;
  views: string[];
  label: string;
  localizer: { messages: Record<string, string>};
  onView: (eventValue: string) => void,
  onNavigate: (action: string) => void
}
