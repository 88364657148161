import { PureComponent, Fragment, MouseEvent } from 'react';
import './EventList.css';
import {fallbackRender, getListEventAPIURL} from './utils';
import './EventList.css';
import EventList from './EventList';
import { fromUTCStrings } from './utils/EventTimes';
import { ErrorBoundary } from 'react-error-boundary';
import {HtmlInterfaceProps} from "./ts/interfaces/html-interface.tsx";
import {EventData} from "./ts/interfaces/event-interfaces.tsx";


export default class ListEventApp extends PureComponent<HtmlInterfaceProps,  ListEventAppState> {
  private requestURL: string;
  constructor(props: HtmlInterfaceProps) {
    super(props);
    const baseURL = 'https://api.socialschools.eu';
    const communityID = this.props.communityId;
    const show = parseInt(this.props.show, 10);
    const numberOfItems = parseInt(this.props.itemCount as string, 10);
    const moreURL = this.props.url;
    this.requestFetch = this.requestFetch.bind(this);
    this.requestURL = getListEventAPIURL(
      baseURL,
      communityID,
      show,
      numberOfItems,
    );
    this.state = {
      eventsList: null,
      next: null,
      previous: null,
      moreURL,
    };
  }

  componentDidMount() {
    this.requestFetch(this.requestURL);
  }

  loadPrevious(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.previous;
    if (requestURL !== null) {
      this.requestFetch(requestURL);
    }
  }

  loadNext(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.next;
    if (requestURL !== null) {
      this.requestFetch(requestURL);
    }
  }

  requestFetch(url: string) {
    fetch(url)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        return {
          results: [],
          next: null,
          previous: null,
        };
      })
      .then((json) => {
        this.setState({
          eventsList: json.results.map(fromUTCStrings),
          next: json.next,
          previous: json.previous,
        });
      });
  }

  render() {
    const nextbntClass = 'btn btn-default nextBtn loadMore float-right';
    const prevbtnClass = 'btn btn-default prevBtn loadMore float-left';
    const isEmpty =
      this.state.eventsList === null || this.state.eventsList.length === 0;
    return (
      <ErrorBoundary fallbackRender={fallbackRender}>
        <div className="eventListSuperContainer">
          {!isEmpty && (
            <Fragment>
              <EventList events={this.state.eventsList} />
              <div className="pagination-wrapper center clearfix">
                {this.state.previous && (
                  <a
                    className={prevbtnClass}
                    onClick={this.loadPrevious.bind(this)}
                    aria-roledescription="loadPrevious"
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true" />
                  </a>
                )}
                {this.state.moreURL && (
                  <a
                    href={this.state.moreURL}
                    className="btn btn-default btn-apps btn-apps-agenda"
                  >
                    Bekijk maandkalender
                  </a>
                )}
                {this.state.next && (
                  <a
                    className={nextbntClass}
                    onClick={this.loadNext.bind(this)}
                    aria-roledescription="loadNext"
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true" />
                  </a>
                )}
              </div>
            </Fragment>
          )}
          {isEmpty && <p>Er zijn geen nieuwe agenda items.</p>}
        </div>
      </ErrorBoundary>
    );
  }
}

interface ListEventAppState {
  eventsList: null | EventData[];
  next: null | string;
  previous: null | string;
  moreURL?: string;
}
