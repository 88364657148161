import { useState, useEffect } from 'react';
import Pagination from './Pagination';
import { getListEventAPIURL } from '../utils';
import List from './List';
import {HtmlInterfaceProps} from "../ts/interfaces/html-interface.tsx";
import {EventData} from "../ts/interfaces/event-interfaces.tsx";

const baseURL = 'https://api.socialschools.eu';

function fetchEvents(url: string) {
  return fetch(url).then((response) => {
    return response.json();
  });
}

function Compact({ communityId, show, itemCount, url}: HtmlInterfaceProps) {
  const showNumber = parseInt(show, 10);
  const numberOfItems = parseInt(itemCount as string, 10);
  const requestURL = getListEventAPIURL(baseURL, communityId, showNumber, numberOfItems);
  const [posts, setPosts] = useState<PostsTypes> ({ next: '', previous: '', results: [] });
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEvents(requestURL).then((json) => {
      setPosts(() => json);
      // setLoading(() => false);
    });
  }, []);

  const loadPosts = (page: string) => {
    if (!['next', 'previous'].includes(page)) return;
    const url = page === 'next' ? posts.next : posts.previous;

    fetchEvents(url).then((json) => {
      setPosts(() => json);
      // setLoading(() => false);
    });
  };


  const { results, next, previous } = posts;

  return (
    <>
      {
        results?.length < 0 ? <p>Geen agenda items gevonden</p>:
        <div className="eventListSuperContainer">
          <List events={results} />
          <Pagination
            url={url}
            previousUrl={previous}
            nextUrl={next}
            loadPosts={loadPosts}
          />
        </div>
      }
    </>

  );
}
export default Compact;


interface PostsTypes {
  next: string;
  previous: string;
  results: EventData[];
}