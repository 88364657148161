import Moment from 'react-moment';
import 'moment/locale/nl';
import { RenderEvent } from '../utils';
import {EventData} from "../ts/interfaces/event-interfaces.tsx";

Moment.globalLocale = 'nl';


const EventModal = ({ event }: EventProps) => (
  <div
    className="modal fade"
    id={`modal_${event.id}`}
    tabIndex={-1}
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{event.title}</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="newsfeed-modal modal-body">
          <RenderEvent event={event} />
        </div>
      </div>
    </div>
  </div>
);


const Event = ({ event }: EventProps) => {
  const { title, id } = event;

  return (
    <div className="compact-post-wrapper gets-rounded gets-shadow--hover mb-3">
      <div className="compact-post-content">
        <h4
          className="compact-post-title post-title mb-2"
          data-toggle="modal"
          data-target={`#modal_${id}`}
        >
          {title}
        </h4>
        <RenderEvent event={event} />
      </div>
    </div>
  );
};

interface EventProps {
  event: EventData;
}

function List({ events }: ListProps ) {
  return (
    <div className="compact-post-container row mt-3">
      <div className="col-12">
        {events.map((event, i) => (
          <div key={`list-${i}`}>
            <Event event={event} />
            <EventModal event={event} />
          </div>
        ))}
      </div>
    </div>
  );
}

interface ListProps {
  events: EventData[] ;
}

export default List;
