import Event from './Event';
import {EventData} from "./ts/interfaces/event-interfaces.tsx";

export default function EventList(props: EventProps) {
  const events = props.events;
  return (
    <div className="eventListContainer row mt-3">
      <div className="col-12">
        {events && events.map((event, key) =>
          <Event event={event} key={event.id} id={key.toString()}/>)
        }
      </div>
    </div>
  );
}

interface EventProps {
  events: EventData[] | null
}