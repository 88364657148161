// add babel polyfill for IE11 users
import { createRoot } from 'react-dom/client';
import './index.css';
import ListEventApp from './ListEventApp';
import CalendarEventApp from './CalendarEventApp';
import Compact from './Compact/Compact';


function renderListApp() {
  const listRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.listevent');
  listRoot.forEach(elem => {
    createRoot(elem).render(
      <ListEventApp
        communityId={elem.dataset.communityId as string}
        show={elem.dataset.show as string}
        itemCount={elem.dataset.itemCount}
        url={elem.dataset.url}
      />,
    );
  });
}

function renderCalenderApp() {
  const calendarRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.calendar');
  calendarRoot.forEach(elem => {
    createRoot(elem).render(
      <CalendarEventApp
        communityId={elem.dataset.communityId as string}
        show={elem.dataset.show as string}
      />,
    );
  });
}

function renderCompactListApp() {
  const compactAgendaRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.compact-agenda-root');
  compactAgendaRoot.forEach(elem => {
    console.log(elem.dataset.itemCount);
    createRoot(elem).render(
      <Compact
        communityId={elem.dataset.communityId as string}
        show={elem.dataset.show as string}
        itemCount={elem.dataset.itemCount}
        url={elem.dataset.url}
      />,
    );
  });
}

renderListApp();
renderCalenderApp();
renderCompactListApp();

document.addEventListener('DOMContentLoaded', function () {
  // Handler when the DOM is fully loaded
  if (window.CMS !== undefined) {
    window.CMS.$(window).on('cms-content-refresh', function () {
      renderListApp();
      renderCalenderApp();
      renderCompactListApp();
    });
  }
});
