/**
 *
 * eventTimes
 * Convert times of an event between different systems
 * Local with time: moment.js objects for start and end, actual time (end > start)
 * Local allDay: moment.js object for start and end, indicating local midnight before first and last day (end >= start)
 * Remote with time: actual times in UTC ISOstring (end > start)
 * Remote allDay: ISOstrings with UTC midnight before for first day and UTC midnight after last day
 *
 */

import moment from 'moment';

/**
 * Convert UTC strings from backend to moment times
 * @param time
 * @returns {{start: *|moment.Moment, end: *|moment.Moment, allDay: boolean|*|string|{id: string, defaultMessage: string}|allDay|{id, defaultMessage}}}
 */
export function fromUTCStrings(time: {start_date: string, end_date: string, all_day: boolean }) {
  const { start_date, end_date, all_day, ...rest } = time;
  const start = stringToTime(start_date, all_day);
  const end = stringToTime(end_date, all_day);
  // If from web20, is has a timezone and allDay end date is exlusive already
  const web20 = end_date.includes('+');
  if (all_day && !web20 ) {
    end.subtract(1, 'day');
  }
  return {
    ...rest,
    start,
    end: end_date && (end.isBefore(start) ? start.clone() : end),
    allDay: all_day
  };
}

function stringToTime(str: string, allDay: boolean | string) {
  if (typeof str === 'string'&& allDay) {
    const match = str.match(/^[\d-]+/);
    if (match) {
      return moment(match[0]);
    }
  }
  return moment(str);
}
