import {Fragment} from 'react';
import Moment from 'react-moment';
import {EventData} from "../ts/interfaces/event-interfaces.tsx";

const getCalendarAPIURL = (baseURL: string, communityID: string, show: number) => {
  let finalURL = `${baseURL}/apiv1/public/${communityID}/event/?upcoming=true`;
  if (show === 1) {
    finalURL = `${baseURL}/apiv1/public/${communityID}/event/?upcoming=true&community_and_descendants=true`;
  } else if (show === 3) {
    finalURL = `${baseURL}/apiv1/public/${communityID}/event/?upcoming=true&only_descendants=true`;
  }
  return finalURL;
};

const getListEventAPIURL = (baseURL: string, communityID: string, show: number, numberOfItems: number): string => {
  let finalURL = `${baseURL}/apiv1/public/${communityID}/event/?upcoming=true&number_of_items=${numberOfItems}`;
  if (show === 1) {
    finalURL = `${baseURL}/apiv1/public/${communityID}/event/?upcoming=true&community_and_descendants=true&number_of_items=${numberOfItems}`;
  } else if (show === 3) {
    finalURL = `${baseURL}/apiv1/public/${communityID}/event/?upcoming=true&only_descendants=true&number_of_items=${numberOfItems}`;
  }
  return finalURL;
};

// class ErrorBoundary extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }
//
//   componentDidCatch(error, info) {
//     this.setState({ hasError: true });
//     console.warn(error, info);
//   }
//
//   render() {
//     if (this.state.hasError) {
//       return <h1>Oops! Something went wrong </h1>;
//     }
//     return this.props.children;
//   }
// }



// styling
export function RenderEvent(props: RenderEventProps) {
    // borrowed this from the EventTimes lib in the utils. we do not need startEnd Parser
    props.event.multiDay =
        !props.event.end ||
        props.event.start?.format('L') !== props.event.end.format('L');
    if (props.event.all_day) {
        props.event.allDay = props.event.all_day;
    }
    if (props.event.allDay) {
        props.event.all_day = props.event.allDay;
    }
    if (props.event.multiDay && !props.event.allDay) {
        // A all day event and that too a multi day is rendered
        // in a different way.
        return (
            // Van vrijdag 21-04-2019 om 08:00 uur tot zaterdag 22-04-2019 om 15:00 uur
            <Fragment>
                Van&nbsp;
                <Moment format="dddd DD MMMM YYYY" locale="nl">
                    {props.event.start}
                </Moment>
                &nbsp;-&nbsp;
                <Moment format="HH:mm" locale="nl">
                    {props.event.start}
                </Moment>
                &nbsp;uur tot en met&nbsp;
                <Moment format="dddd DD MMMM YYYY" locale="nl">
                    {props.event.end}
                </Moment>
                &nbsp;-&nbsp;
                <Moment format="HH:mm" locale="nl">
                    {props.event.end}
                </Moment>
                &nbsp;uur
            </Fragment>
        );
    }
    if (props.event.multiDay && props.event.allDay) {
        return (
            <Fragment>
          <span>
            Van&nbsp;
              <Moment format="dddd DD MMMM YYYY" locale="nl">
              {props.event.start}
            </Moment>
              &nbsp; tot en met&nbsp;
              <Moment format="dddd DD MMMM YYYY" locale="nl">
              {props.event.end}
            </Moment>
              &nbsp;
          </span>
            </Fragment>
        );
    }
    if (props.event.allDay && !props.event.multiDay) {
        // Single day event
        // not a multi day event so renders in different way.

        // single day all_day event
        return (
            <Fragment>
                Op&nbsp;
                <Moment className="moment-one" format="dddd DD MMMM YYYY" locale="nl">
                    {props.event.start}
                </Moment>
                &nbsp;de gehele dag
            </Fragment>
        );
    }
    if (!props.event.allDay && !props.event.multiDay) {
        // single day event with different timings
        return (
            <Fragment>
                Van&nbsp;
                <Moment format="dddd DD MMMM YYYY" locale="nl">
                    {props.event.start}
                </Moment>
                &nbsp;-&nbsp;
                <Moment format="HH:mm" locale="nl">
                    {props.event.start}
                </Moment>
                &nbsp;uur tot&nbsp;
                <Moment format="HH:mm" locale="nl">
                    {props.event.end}
                </Moment>
                &nbsp;uur
            </Fragment>
        );
    }
}


interface RenderEventProps {
  event: EventData
}


function fallbackRender({ error }: { error: Error }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}


export { fallbackRender, getCalendarAPIURL, getListEventAPIURL };
