import { Fragment, PureComponent } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl';
import './Calendar.css';
// Import the standard CSS as given in the docs
// we are keep the standard plus our own as we have some overriding done in the local one.
// In ideal world, the second one & the styling in theme.html file should only keep styling overriding
// that are cosmetic in nature(just change how it looks and not how it works)
// TODO:@shanicetan92 please look into this.
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './react-big-calendar.css';
import './Event.css';
import './EventList.css';
import { RenderEvent } from './utils';
import CustomToolbar from './CustomToolbar';
import { EventData } from "./ts/interfaces/event-interfaces.tsx";

interface CalendarAppProps {
  events?: EventData[]; // Update with the appropriate type for events
}

interface CalendarState {
  selectedEvent: EventData | null; // Update with the appropriate type for selectedEvent
  display: string;
}


const localizer = momentLocalizer(moment);
localizer.segmentOffset = 0;

export default class Calendar extends PureComponent<CalendarAppProps, CalendarState>  {
  constructor(props: CalendarAppProps) {
    super(props);
    this.selectEvent = this.selectEvent.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      selectedEvent: null,
      display: 'none'
    };
  }

  escFunction(event: globalThis.KeyboardEvent) {
    if (event.key === 'Escape') {
      this.setState({ selectedEvent: null });
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.escFunction, false);
  }

  selectEvent(event: EventData) {
    this.setState({ selectedEvent: event, display: 'block' });
  }

  hideModal() {
    this.setState({ selectedEvent: null });
  }

  getCalendarStartDate(event: EventData) {
    // Convert back to JS Date objects
    // IMP REF: https://github.com/jquense/react-big-calendar/issues/163
    const date = moment(event.start);
    return event.allDay ? date.startOf('day').toDate() : date.toDate();
  }

  getCalendarEndDate(event: EventData) {
    // Convert to JS Date objects as above
    const date = moment(event.end);
    let endDate = event.allDay ? date.add(1, 'minutes').endOf('day').toDate() : date.toDate();
    if (event.allDay) {
      console.log("==================================================")
      console.log(event);
      console.log(`TITLE: ${event.title}`);
      console.log(`StartDate: ${event.start} | MomentStartDate: ${moment(event.start)}`);
      console.log(`AllDay: ${event.allDay} | EventEndDate: ${event.end} | MomentEndDate: ${date} | ConvertedEndDate: ${endDate}`);
    }
    return endDate;
  }

  getTitle(event: EventData) {
    return event.allDay ? event.title : `${moment(event.start).format('LT')} ${event.title}`;
  }

  render() {
    const components = {
      toolbar: CustomToolbar
    };

    // let EVENTS = [];
    const messages = {
      allDay: 'de gehele dag',
      previous: '←',
      next: '→',
      today: 'Vandaag',
      month: 'maand',
      work_week: 'Werkweek',
      week: 'week',
      day: 'dag',
      agenda: 'agenda'
      // showMore: "meer zien"
    };
    // TODO: YURI RECH -> Check for the better type of BigCalendar
    return (
      <Fragment>
        <BigCalendar
          {...this.props}
          localizer={localizer}
          onSelectEvent={this.selectEvent}
          popup={true}
          messages={messages}
          components={components as any}
          titleAccessor={this.getTitle}
          startAccessor={this.getCalendarStartDate as any}
          endAccessor={this.getCalendarEndDate as any}
        />
        {this.state.selectedEvent && (
          <div
            className="modal show calendarModal"
            tabIndex={-1}
            role="dialog"
            style={{ display: this.state.display }}
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="false"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title modal-event-title">
                    {this.state.selectedEvent.title}{' '}
                    {this.state.selectedEvent._community_name !== '' && (
                      <span> {this.state.selectedEvent._community_name}</span>
                    )}
                  </h4>{' '}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.hideModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="eventContainer row pr-3 pl-3">
                    <div className="eventStartDate col-md-2 p-0">
                      <div className="eventMonth bg-primary rounded-top pb-1">
                        <h4 className="card-title text-center text-light mb-0">
                          <Moment format="MMM" locale="nl">
                            {this.state.selectedEvent.start}
                          </Moment>
                        </h4>
                      </div>
                      <div className="border rounded-bottom border-top-0">
                        <p className="eventDate agendaeventDate card-title text-center pt-3">
                          <Moment format="DD" locale="nl">
                            {this.state.selectedEvent.start}
                          </Moment>
                        </p>
                      </div>
                    </div>
                    <div className="eventDetails col-md-10">
                      <RenderEvent event={this.state.selectedEvent} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
