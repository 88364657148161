import {Fragment} from 'react';
import Moment from 'react-moment';
import 'moment/locale/nl';
import './Event.css';
import {RenderEvent} from './utils';
import {EventData} from "./ts/interfaces/event-interfaces.tsx";

// renderEvent method to parse the incoming event &
// render the event in a certain fashion as the per socs3.0

export default function Event(props: EventProps) {
  const {event} = props;
  const key = props.id;
  if (event) {
    const idTarget = event.id !== undefined ? `#${event.id}` : `#${key}`;
    const modalID = event.id !== undefined ? event.id : key;

    return (
      <Fragment>
        <div
          className="agendaeventContainer card mb-3 gets-rounded gets-shadow--hover"
          data-toggle="modal"
          data-target={idTarget}
        >
          <div className="card-body p-3">
            <div className="col-8 col-md-12 col-lg-8 float-left pl-0">
              <h4 className="card-title event-title text-truncate mb-1">
                <span className="eventPostTitle inline cboxElement">{event.title}</span>
              </h4>
              <div className="col-12 px-0 card-text text-truncate agendaeventDateDetails">
                <RenderEvent event={event}/>
              </div>
            </div>

            <div className="col-4 d-md-none d-lg-block float-right pl-0 pr-0 agendaeventStartDate">
              <div className="agendaeventMonth d-flex flex-column float-right mr-1">
                <div className="bg-primary rounded-top pb-1">
                  <h4 className="card-title text-center text-light mb-0">
                    <Moment format="MMM" locale="nl">
                      {event.start}
                    </Moment>
                  </h4>
                </div>
                <div className="border rounded-bottom border-top-0">
                  <p className="agendaeventDate card-title text-center">
                    <Moment format="DD" locale="nl">
                      {event.start}
                    </Moment>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade modal__eventDetails"
          id={modalID?.toString()}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title modal-event-title">{event.title}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="eventContainer row">
                  <div className="eventStartDate col-md-2 p-0">
                    <div className="eventMonth bg-primary rounded-top pb-1">
                      <h4 className="card-title text-center text-light mb-0">
                        <Moment format="MMM" locale="nl">
                          {event.start}
                        </Moment>
                      </h4>
                    </div>
                    <div className="border rounded-bottom border-top-0">
                      <p className="eventDate agendaeventDate card-title text-center pt-3">
                        <Moment format="DD" locale="nl">
                          {event.start}
                        </Moment>
                      </p>
                    </div>
                  </div>
                  <div className="eventDetails col-md-10">
                    <RenderEvent event={event}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

}


interface EventProps {
  event?: EventData;
  id?: string;
}


